/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Poppins";
  src: url("/Poppins-ExtraLight.ttf") format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/Poppins-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/Poppins-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/Poppins-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/Poppins-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/Poppins-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  background: #fff;
  font-family: 'Poppins', sans-serif;
}
.has-transition {
  transition: all 0.3s;
}
button, a {
  transition: all 0.3s;
  &.primary {
    padding: 12px 30px;
    border-radius: 20px;
    background: #000;
    font-size: 16px;
    border: 1px solid #000;
    color: #fff;
    text-transform: uppercase;
    &:hover {
      background: #555;
      color: #fff;
      border: 1px solid #555;
    }
    @media screen and (max-width: 399px) {
      padding: 10px 20px;
    }
  }
  &.secondary {
    padding: 12px 30px;
    border-radius: 20px;
    background: #fff;
    font-size: 16px;
    border: none;
    color: rgba(0,0,0,0.4);
    text-transform: none;
    &:hover {
      color: #000;
    }
    @media screen and (max-width: 399px) {
      padding: 10px 20px;
    }
  }
  &:disabled {
    opacity: 0.6;
    color: #000;
    background: transparent;
    cursor: not-allowed;
  }
}
